import React from "react"
import styles from "./ServiziCorrelati.module.scss"

const ServiziCorrelati = ({ link }) => {
  const elenco_link = link.map((servizio, index) => {
    return <li key={index}>{servizio.post_title}</li>
  })
  return (
    <div className={styles.serviziContainer}>
      <h3>Servizi</h3>
      <ul>{elenco_link}</ul>
    </div>
  )
}
export default ServiziCorrelati
