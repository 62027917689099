import React from "react"
import Description from "../components/Description/Description"
import ServiziCorrelati from "../components/ServiziCorrelati/ServiziCorrelati"
import Heading from "../components/Heading/Heading"
import styles from "./templateProgettiArchiviati.module.scss"
import ProjectList from "../components/ProjectList/ProjectList"
import parse from "html-react-parser"
import Header from "../components/Header/Header"
import TransitionBehavior from "../components/TransitionBehavior/TransitionBehavior"
import SEO from "../components/seo"
import HeaderImg from "../components/HeaderImg/HeaderImg"

const TemplateProgettiArchiviati = props => {
  const { pageContext } = props
  const { progettiArchiviati, acfProgettiArchiviati, meta } = pageContext
  const {
    titolo,
    descrizione,
    sottotitolo,
    link_correlati,
  } = acfProgettiArchiviati

  const projectArray = progettiArchiviati.filter(({ node }) => {
    return node.acf.titolo !== titolo
  })

  const { transitionStatus } = props

  let transitionClass = "transitionContents " + transitionStatus

  return (
    <TransitionBehavior>
      <div className={transitionClass}>
        <Header />
        <SEO meta={meta} />
        <HeaderImg>
          <Heading
            title={parse(titolo)}
            subtitle={sottotitolo}
            ish1={true}
          ></Heading>
        </HeaderImg>
        <div className={styles.containerMedium}>
          <div
            className={[
              styles.descriptionContainer,
              styles.containerMedium,
            ].join(" ")}
          >
            <ServiziCorrelati
              link={link_correlati}
              className={styles.containerVerySmall}
            />
            <Description desc={descrizione} className={styles.containerSmall} />
          </div>
          <div
            className={[styles.containerMedium, styles.listaServizi].join(" ")}
          >
            <ProjectList
              projectArray={projectArray}
              immagine={false}
              testimonial={false}
              verticalList={true}
            />
          </div>
        </div>
      </div>
    </TransitionBehavior>
  )
}

export default TemplateProgettiArchiviati
